{
  "name": "@openmove-toolbelt/api",
  "version": "0.4.27",
  "description": "",
  "main": "dist/index.js",
  "scripts": {
    "build": "node ./scripts/getFallbackJSON.js && parcel build --no-minify src/index.js --dist-dir dist"
  },
  "license": "ISC",
  "devDependencies": {
    "axios": "0.21.1",
    "graceful-fs": "4.2.6",
    "parcel": "2.0.0-beta.1"
  },
  "dependencies": {
    "node-fetch": "^2.6.1"
  }
}
