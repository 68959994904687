const fetch = require('node-fetch')
  , {getApiFromJsonUrl} = require('./urls')
  , isJson = (str) => {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }

//global vars
  , requestTypesWithBody = ['POST', 'PUT', 'PATCH']
  , successStatusCodes = [200, 204]

  , querySerialize = (obj) => {
      let str = '?' + Object.keys(obj).reduce(function(a, k){
          a.push(k + '=' + encodeURIComponent(obj[k]));
          return a;
      }, []).join('&');
      return str;
    }

  , manageACallback = (res, callback) => {
      if(callback){
        callback(res);
      }
      return res;
    }

  , fetchApi = async (initRequest, url, obj, callback) => {
      try {
        const {theUrl, request} = initRequest(url, obj)
          , response = await fetch(theUrl, request)
          , {'status': responseStatusCode} = response;

        let responseJSON = {};
        if(responseStatusCode === 200){
          responseJSON = await response.json();
        }

        if(!successStatusCodes.includes(responseStatusCode)){
          responseJSON.err = true;
          const errMsg = await response.text();
          try {
            responseJSON.errMsg = {
              'statusCode': responseStatusCode,
              'error': 'Error',
              'message': JSON.parse(errMsg)
            };
          }
          catch(e){
            responseJSON.errMsg = {
              'statusCode': responseStatusCode,
              'error': 'Error',
              'message': errMsg
            };
          }
        }

        return manageACallback(responseJSON, callback)
      }
      catch (e){
        return manageACallback({err: e}, callback)
      }
    }


module.exports = ({
  'Api': ({contexts, tenant, channel, apiUrls = []}) => {
    const {auth} = contexts
      , initRequest = (url, {
          method = 'GET',
          body = {},
          headers = {},
          params
        }) => {
          const {token} = auth
            , request = {
                method,
                'headers': {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  tenant,
                  channel,
                  ...headers
                }
              };
          let theUrl = url;

          if (requestTypesWithBody.includes(method) && body) {
            request.body = JSON.stringify(body);
          }

          if (params) {
            theUrl += querySerialize(params);
          }

          return {theUrl, request}
        }
      , urls = async() => {
          const response = {};
          for(const {url, moduleName} of apiUrls){
          try {
              response[moduleName] = await getApiFromJsonUrl(url, moduleName);
            }
            catch(e){
              console.warn(e.message);
            }
          }
        return response;
        }

    return {
      'fetch': async(...args) => await fetchApi(initRequest, ...args),
      urls
    }
  }
})
