const fetch = require('node-fetch')
  , packageJSON = require('../../package.json')

  // XXX Error in React API: Dynamic require defined at line 336; not supported by Metro
  // , apisFallback = Object.entries(packageJSON.apis, ([key]) => ({
  //     [key] : ''
  //   })).reduce((prev, [key, value]) => ({
  //     ...prev,
  //     [key] : require(`./fallback/${key}`)
  //   }), {})

  , apisFallback = require('./fallback')
  // {
  //     "auth": require('./fallback/auth'),
  //     "cap": require('./fallback/cap'),
  //     "way": require('./fallback/way'),
  //     "tenants": require('./fallback/tenants'),
  //   }
  , isParam = value => (/{.*}/).test(value)
  , removeParamsInObject = (param) => {
      return param.replace(/{/, '').replace(/}/, '')
    }
  , createPath = (obj, thePath, value = null) => {
      let path = typeof thePath === 'string' ? thePath.split('/').splice(1) : thePath
        , current = obj;

      while (path.length > 1) {
        const [head, ...tail] = path;
        path = tail;

        if (current[head] === undefined) {
          current[head] = {};
        }

        if(isParam(head)){
          current[head].isParam = true;
          current[head].url = value.url;
        }
        current = current[head];
      }

      const currentPath = path[0];
      if(isParam(currentPath)){
        current[currentPath] = {...value, 'isParam': true}
      }
      else {
        current[currentPath] = value;
      }
      return obj;
    }
  , formatResponse = (domain, obj, url = '') => {
      const keys = Object.keys(obj)
        , response = {};

        for(const key in keys){
          const theItem = obj[keys[key]];
          let newUrl = `${url}/${keys[key]}`;

          if(!['url', 'isParam'].includes(keys[key])){
            const currentPath = removeParamsInObject(keys[key]);

            if(theItem.isParam === true){

              response[currentPath] = (aParam) => {
                newUrl = newUrl.replace(`${keys[key]}`, aParam);

                return formatResponse(domain, {...theItem, 'url': newUrl}, newUrl)
              };
            }
            else {
              response[currentPath] = formatResponse(domain, theItem, newUrl);
            }
          }
          else if(keys[key] === 'url'){
            response[keys[key]] = `${domain}${url}`;
          }
      }

      return response;
    }

module.exports = ({
  'getApiFromJsonUrl': async (url, moduleName) => {
    let responseJSON = {};

    try {
      const response = await fetch(url)
        , {'status': responseStatusCode} = response;

      if(responseStatusCode !== 200){
        throw new Error(`Invalid JSON for module ${moduleName} at url ${url}`)
      }

      responseJSON = await response.json();
    }
    catch(e){
      console.warn(`Api JSON for module ${moduleName} at url ${url} thrown an error: ${e.message}`);
    }

    if(!responseJSON.paths){
      responseJSON = apisFallback[moduleName] || responseJSON;

      if(!responseJSON.paths){
        console.warn(`Cannot get fallback Api JSON for module ${moduleName}: ${e.message}`);
        responseJSON.paths = {};
      }
    }

    const domain = url.replace('/json', '')
      , paths = Object.keys(responseJSON.paths)
      , result = {};

    for(const key in paths){
      const path = paths[key];
      createPath(result, path, { 'url': paths[key]});
    }

    return formatResponse(domain, result);
  }
});
