const apis = {
  "auth": "https://test-auth.nucleus.openmove.com/json",
  "cap": "https://test-capgemini.autostradale.nucleus.openmove.com/json",
  "way": "https://test-way.nucleus.openmove.com/json",
  "tenants": "https://test-tenants.nucleus.openmove.com/json",
  "drt": "https://test-drt.nucleus.openmove.com/json"
}
, files = {
  "auth": require('./auth'),
  "cap": require('./cap'),
  "way": require('./way'),
  "tenants": require('./tenants'),
  "drt": require('./drt')
}

module.exports = {
  apis,
  files
}
